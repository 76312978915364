.dashboard-left-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
}

.dashboard-register-container{
    width: 100%;
    /* min-height: 400px; */
    height: fit-content;
    margin: 20px 10px 5px 20px;
    background: rgb(255, 255, 255);
    text-align: center;
    border-radius: 8px;
}

.dashboard-logs-container {
    width: 100%;
    height: 100%;
    margin: 5px 10px 3px 20px;
    background: black;
    color: #4af626;
    overflow-y: auto;
    text-align: left;
    border-radius: 2px;
    display: flex;
    flex-direction: column-reverse;
    white-space: pre-wrap;
}

.dashboard-log-tab-selector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    width: 98%;
}

.dashboard-logs-refresh-button {
    padding: 6px 16px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.1s ease;
    border-radius: 5px;
}

.dashboard-logs-refresh-button:hover {
    background-color: #e0e0e0;
}

.dashboard-logs-refresh-button.active {
    background-color: #dddddd;
    border: 3px solid #0e4c60;
}

.dashboard-logs-refresh-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.dashboard-userlist-container{
    width: 70%;
    /* min-height: 400px; */
    height: 95%;
    margin: 20px 20px 20px 10px;
    background: rgb(255, 255, 255);
    text-align: center;
    border-radius: 8px;
    overflow-y: auto;
}

/* @media only screen and (max-width: 550px) {
    .dashboard-container{
        width: 100%;
    }
}


@media only screen and (max-width: 550px) {
    .dashboard-container{
        width: 100%;
    }
} */

.dashboard-container h3{
    font-size:23px;
    font-weight:600;
    color:black;
}

.dashboard-brand{
    /* margin: 50px auto 30px auto; */
    margin: 0 auto;
    padding-top:50px;
    padding-bottom: 30px;
}
.dashboard-bottom{
    font-size: 14px;
    margin-top: 40px;
}
.dashboard-anchor{
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    color: #4c4b4c;
    margin-left:5px;;

}

.dashboard-register-button{
    background-color: #0e4c60;
    margin-top: 20px;
    margin-bottom: 25px;
    width: 72%;
    height: 35px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dashboard-form-group-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard-form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    text-align: left;
}
.dashboard-message{
    color: red;
    font-size: 13px;
    
}
.dashboard-label {
    display: inline-block;
    margin-bottom: 3px;
    color: #9a9a9a;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
}

.dashboard-form-control {
    box-sizing: border-box;
    display: block;
    width: 99%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    padding: 10px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.dashboard-spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dashboard-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.dashboard-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-close-modal-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dashboard-close-modal-button:hover {
    background-color: #218838;
}

.dashboard-activate-button,
.dashboard-deactivate-button,
.dashboard-make-admin-button,
.dashboard-remove-admin-button {
    width: 120px;
    padding: 8px;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dashboard-activate-button:hover,
.dashboard-make-admin-button:hover {
    background-color: rgb(64, 250, 64);
}

.dashboard-deactivate-button:hover,
.dashboard-remove-admin-button:hover {
    background-color: rgb(247, 100, 100);
}