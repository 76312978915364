.button-location-covered{
    text-decoration: underline;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
}
.topic-tags-form{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;;
}
.topic-tags-form-group {
    margin-bottom: 1rem;
    text-align: left;
    padding: 0 15px 0 0;
}
.topic-tags-message{
    color: red;
    font-size: 13px;
    
}
.topic-tags-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: grey;
    font-weight: 600;
    font-size: 12px;
}
.topic-tags-form-control {
    display: block;
    width: 99%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 6px 10px;
}

.profile-form{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 70px;
    grid-gap:25px;
}
.profile-form-group {
    text-align: left;
    padding: 0 15px 0 0;
    margin-bottom:10px;
}
.profile-message{
    color: red;
    font-size: 13px;
    
}
.profile-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: grey;
    font-weight: 600;
    font-size: 12px;
}
.profile-form-control {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 6px 10px;

}
.topic-tags-button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 42px;
    margin-top: 6px;
    border: none;
    border-radius: 4px;
    background: gray;
    color: white;
    cursor: pointer;
}
.tag-remove{
    color: gray;
    cursor: pointer;
    font-size: 25px;
    margin: 0 5px;
    margin-top: -4px;
    float: left;
}
.tag-remove:after{
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
  }
.tag-content{
    float:right;
    margin:7px 5px 7px 0px;
    padding-right:8px;
    font-weight:600;
    color:grey;
}
.lawmaker-container{
    display: grid;
    grid-template-columns: 3fr 1fr;
    min-height: 100px;
}
.lawmaker-left{
    border-right:1px solid #ced4da;
}
.lawmaker-right{
    text-align: left;
    padding-left:20px;
}


.lawmaker-form{
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.lawmaker-form-group {
    margin-bottom: 1rem;
    text-align: left;
    padding: 0 15px 0 0;
}
.lawmaker-message{
    color: red;
    font-size: 13px;
    
}
.lawmaker-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: grey;
    font-weight: 600;
    font-size: 12px;
}
.lawmaker-label-or {
    display: inline-block;
    margin: 18px 0px !important;
    color: grey;
    font-weight: 600;
    font-size: 16px;
}
.lawmaker-form-control {
    display: block;
    width: 99%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.lawmaker-button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 42px;
    margin-top: 6px;
    border: none;
    border-radius: 4px;
    background: gray;
    color: white;
    cursor: pointer;
}

.lookup-lawmaker-form{
    padding-right:20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:25px;
}
.lookup-lawmaker-form-group {
    margin-bottom: 1rem;
    text-align: left;
    padding: 0 15px 0 0;
}
.lookup-lawmaker-message{
    color: red;
    font-size: 13px;
    
}
.lookup-lawmaker-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: grey;
    font-weight: 600;
    font-size: 12px;
}
.lookup-lawmaker-form-control {
    display: block;
    width: 99%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 6px 10px;
}
.lookup-lawmaker-button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 32px;
    margin-top: 6px;
    border: none;
    border-radius: 4px;
    background: gray;
    color: white;
    cursor: pointer;
}

.lawmaker-checkbox{
    border: 1px solid #ced4da;
    height: 300px;
    border-radius: 4px;
    margin-bottom: 5px;
    overflow-y: scroll;
    padding: 15px;
}

.lawmaker-checkbox-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.lawmaker-chamber-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/*start checbox style  */
/* The check-container */
.check-container {
 
    display: block;
    position: relative;
    padding-left: 34px;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    padding: 0px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    overflow: hidden;
  }
  
  /* On mouse-over, add a grey background color */
  .check-container:hover input ~ .checkmark {
    background-color: white;
   
  }
  
  /* When the checkbox is checked, add a blue background */
  .check-container input:checked ~ .checkmark {
    background-color: white;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .check-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .check-container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
/* End checkbox style */



.account-information-form{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
	margin-block-start: 1rem;
}
.account-information-form-group {
    text-align: left;
    padding: 0 15px 0 0;
}
.account-information-message-error{
    color: red;
    font-size: 13px;
    text-align: center;
    padding-bottom: 5px;
    
}
.account-information-message-success{
    color: green;
    font-size: 13px;
    text-align: center;
    padding-bottom: 5px;
    
}
.account-information-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #959595;
    font-weight: 500;
    font-size: 11px;

	& span {
		color: red;
	}
}
.account-information-form-control {
    display: block;
    width: 99%;
    font-size: 14px;
    height: 35px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
	padding-left:10px;
    padding-right:10px;

	&:disabled {
		background-color: #ccc;
	}
}
.button-account-information{
    background-color: #4c4b4c;
    width: 33%;
    height: 35px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color:#fff;
    font-weight: 600;
    margin: 25px 0 20px 0;
}
.button-change-password{
    text-decoration: underline;
    font-weight: 600;
    font-size: 13px;
    float: right;
    cursor: pointer;
}
.left-menue-hover:hover{
    color: black;
}

.menuIcons{
    margin-top:1px;
    width:18px !important;
    height:18px !important;
}


.removeBtn{
    width: 80px;
    height: 32px;
    border: none;
    border-radius: 4px;
    background: #4c4b4c;
    color: white;
    cursor: pointer;
}
.BodyBoxTitle{
    font-weight:bolder !important;
    margin-bottom:20px !important;

}

.BodyBox{
    padding:25px !important;
}

.FilterBoxes{
    margin-top:40px !important;
}

.or{
    margin: 20px 0px !important;
}

.css-1pmvkci-IndicatorsContainer {
    display:none !important;
}

#react-select-3-placeholder{
    color:#e5e5e5;
    font-size: 12px;
    font-weight: 500;
}

.lawmakerTag{
    float:left;
    display:block !important;
    margin: 5px 0px !important;
    width:180px;

}

.lawmakerTag .tag-content {
    float: left !important;
}

.swal-button {
    background-color: #4c4c4c;
  
}
