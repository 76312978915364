.archive-content {
    margin: 2rem 3.5rem;
    display: block;
    text-align:center;
    padding: 10px;
    min-height: 0;
    min-width: 0;
}

.record-container {
    width:100%;
        display: flex;
    justify-content: center;
}
.archive-record {
    background-color: #0e4c60;
    border-radius: 10px;
    padding-left:5px;
    padding-right:5px;
    text-align:left;
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom:5px;
    width:800px;
    align-items: center;
}

.archive-title {
    width:90%;
    overflow: hidden;
}

.archive-score {
    color: #FCD70B;
    min-width:25px;
    display: inline-block;
    text-align:right;
    padding-right:5px;
}

.archive-record-metadata {
    min-width:170px;
    display: inline-block;
    text-align:right;
    padding-right:5px;
}
.archive-record a:link, .archive-record a:visited{
    color:white;
}
.archive-record a:active {
    color: #FCD70B;
}
.archive-links-list-item-link {
    text-decoration: none;
}
.archive.cards{
    margin-top: 20px;
}

.period-header-archive {
    margin-bottom:20px;
    font-size:20px;
}
