
.summary_vitals,.summary_vitals .person{
    list-style: none;
    font-weight: 600;
    }
    .summary_vitals_key,.summary_vitals_value{
        display: inline-block;
    }
    .summary_vitals_value{
        padding-left: 50px;
        
    }
    .summary_vitals_key{
        width:90px;
        text-transform: capitalize;
    }
    .summary_vitals_key:after {
        content: ":"
    }
    .summary_vitals_item{
        height: 5px;
    }

.summary-right-panel {
    padding-top: 20px;
    padding-left: 20px;
}

.summary-right-panel .container {
    display: flex;
    padding: 20px;
    width: 80%;
    justify-content: center;
    position: relative;
}

.tooltip {
  display: none;
  background-color: #0e4c60;
  border: 1px solid #0a3746;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  top: 120%; /* Position the tooltip above the abbreviation */
  left: 50%; /* Start the tooltip halfway across the abbreviation */
  transform: translateX(-50%); /* Center the tooltip */
}

.subsection-subheadline {
    font-size: 10px;
    margin-bottom: 10px;
    padding:10px;
}

.org-name-gauge {
    width:50px;
    color: red;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.publish-date {
    text-align:right;
    font-size:10px;
    margin-top:5px;
    margin-right:20px;
    height:5px;
}

.vote-results-right {
    text-align:center;
    display: flex;
    justify-content: center;
    padding:20px;
}
.vote-breakdown-right {
    text-align:left;
    margin-left:0;
    margin-top:25px;
}

.information-tip {
    text-align:left;
    font-size:10px;
}

.information-tip:before {
    content:'[\2139] ';
    font-size:14px;
    color: blue;
}

.video-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.video-element {
    display: block;
    width: 100%;
    height: auto;
}


.play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
}

.play-button {
    width: 50px;
}

.play-button-small {
    width: 20px;
    display: inline-block;
}

.sankey-container-large, .sankey-container-medium, .sankey-container-small {
    padding:25px;
    width:400px;
}

.sankey-container-medium {
    height:500px;
}

.sankey-container-large {
    height:1000px;
}

.sankey-container-xlarge {
    height:1500px;
}

.information-gauges {
    display:flex;
    padding:50px;
    padding-top:10px;
    padding-bottom:10px;
    margin-bottom:0px;
    width:80%;
    margin-left:25px;
}

.information-gauge-panel {
    padding:20px;
    padding-top:0px;
    padding-bottom:0px;
    width:150px;
}

.information-gauge-text {
    font-size: 12px;
    padding-left:0px;
    width: 100%;
    text-align: left;
}

.information-gauge-title {
    font-size:12px;
    font-weight:bold;
    padding-left:50px;
    margin-top:20px;
    margin-left:25px;
}

.small-gauges-alignment-meters {
    display: inline-block;
    padding:10px;
}

.gauge-display-small {
    display: inline-block;
}

.no-decoration a {
    text-decoration: none;
}

.veto-message {
    padding-left:40px;
}

.calendar-container {
    height:220px;
    padding:40px;
}

.tipsheets-table-summary-right-panel {
    margin-top:-20px;
}

.tipsheets-table-summary-right-panel .google-visualization-table-table {
    font-size:12px;
}

.tipsheets-table-summary-right-panel h3 {
    font-size:14px;
    font-weight:bold;
    margin-left:20px;
}