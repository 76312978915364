.link {
  text-decoration: none;
}

.tooltip {
  display: none;
  background-color: #0e4c60;
  border: 5px solid black;
  padding: 10px;
  border-radius: 3px;
  position: absolute;
  z-index: 5;
  font-size: 10px;
  width: 400px;
  left: -10%; /* Start the tooltip halfway across the abbreviation */
}

.tooltip ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
}

.vote-pass-card:after {
  color: #fcd70b;
  font-weight: bold;
  content: '  \2714  ';
}

.vote-fail-card:after {
  color: #fcd70b;
  font-weight: bold;
  content: '  \2718  ';
}

.vote-not-available:after {
  color: #fcd70b;
  font-weight: bold;
  content: '  VOTE RESULTS PENDING  ';
}
