.account-container{
    min-width: 370px;
    width: 550px;
    min-height: 400px;
    background: rgb(255, 255, 255);
    text-align: center;
    margin: 10px auto 0 auto;
    border-radius: 8px;
}

@media only screen and (max-width: 550px) {
    .account-container{
        width: 100%;
    }
}


@media only screen and (max-width: 550px) {
    .account-container{
        width: 100%;
    }
}

.account-container h3{
    font-size:23px;
    font-weight:600;
    color:black;
}

.account-brand{
    /* margin: 50px auto 30px auto; */
    margin: 0 auto;
    padding-top:50px;
    padding-bottom: 30px;
}
.account-bottom{
    font-size: 14px;
    margin-top: 40px;
}
.account-anchor{
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    color: #4c4b4c;
    margin-left:5px;;

}
.google-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}
.account-button{
    background-color: #0e4c60;
    margin-top: 10px;
    width: 72%;
    height: 35px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    color:white;
}
.account-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.account-form-group {
    width: 90%;
    margin-bottom: 28px;
    text-align: left;
}
.account-message{
    color: red;
    font-size: 13px;
    
}
.account-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #9a9a9a;
    font-weight: 600;
    font-size: 12px;
}

.account-form-control {
    box-sizing: border-box;
    display: block;
    width: 99%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    padding: 10px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.backgroundDiv {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width:100vw;
    height:100vh;
}