.about-box {
    display: block;
    border-radius: 10px;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background: white;
    min-height: 245px;
    width: 500px;
    position: absolute;
    top:20%;
    left:50%;
    padding:10px;
    margin-top:-50px; /* this is half the height of your div*/
    margin-left:-250px; /*this is half of width of your div*/
    justify-content: center;
    align-items: center;
}

.logos-box {
    display:block;
    clear:both;
    text-align:center;
    align-items: center;
    padding-top: 50px;
}
.logos-box img {
    height:50px;
    padding:5px;
    margin-left:5px;
    margin-right:5px;
}
