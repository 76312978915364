.filter-button{
    background-color: #4c4b4c;
    color: #fff;
    width: 270px;
    height: 35px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    display: block;
    margin: 5px auto;
}
.skip-button{
    text-decoration: underline;
    display: block;
    margin: 5px auto;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: #4c4b4c;
    margin-top: 20px;;

}

.interest_checkbox{
    margin:0px;
    border-color: #838282;

}

.checkmark {
top:-3px !important;

}