body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100%;
  background-color: #e5e5e5;
}

h3 {
  font-size: 20px;
}

html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headline_text {
  font-size: 2em;
}

.byline_text,
.time_text,
.date_text {
  margin-top: 10px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: lightgray;
  display: inline-block;
  font-size: 0.8em;
  font-family: 'Lucida Console', Monaco, monospace;
  border: 1px solid;
}

.date_text {
  margin-right: auto;
  border-left: 1px solid;
}

p {
  display: inline-block;
  margin-top: 10px;
  white-space: pre-wrap;
}

.person {
  padding-left: 0px;
  display: inline-block;
  font-weight: normal;
  white-space: pre-wrap;
}

.pullquote {
  margin-bottom: 20px;
  margin-left: 30px;
  width: 90%;
  font-style: italic;
  font-size: 16px;
  color: darkblue;
}

.pullquote::before {
  content: '“ ';
  font-family: 'Times New Roman', serif;
  color: darkblue;
  font-weight: bold;
  font-size: 4em;
  margin-bottom: -30px;
}

.quote_author_affiliation {
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 20px;
  font-style: normal;
  color: black;
  text-align: right;
  font-size: 12px;
}

.quote_author_affiliation::before {
  content: '-- ';
}

.quote_author_affiliation a:link {
  text-decoration: none;
}

.notes {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted;
  border-top: 1px dotted;
  font-size: 0.75em;
}
.note {
  text-indent: 20px;
}

.endnotes_text {
  padding: 20px;
  font-size: 0.75em;
  text-align: center;
}

/* start input placeholder */
::placeholder {
	color: #e5e5e5;
	opacity: 1; /* Firefox */
	font-size: 12px;
  }
  
  :-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #e5e5e5;
	font-size: 12px;
  }
  
  ::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #e5e5e5;
	font-size: 12px;
  }
  
  /* width */
  ::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #c9c9c9;
	border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #c9c9c9;
	border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #c9c9c9;
  }
  